@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  color: #495057;
}

html {
  overflow: hidden;
  width: 100%;
}

body {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

*,
*::before,
*::after {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

input {
  -webkit-user-select: text;
  /* Chrome, Opera, Safari */
  -moz-user-select: text;
  /* Firefox 2+ */
  -ms-user-select: text;
  /* IE 10+ */
  user-select: text;
  /* Standard syntax */
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}

select:focus,
input:focus {
  outline: none;
  @apply focus:ring-0 focus:ring-offset-0;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f8fa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #011e3d2f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #011e3d5f;
}


/**
 * Efecto de carga para los botones con la propiedad
 * loading = true
 */
.button span {
  display: flex;
}

.button span b {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: stretch;
  animation-play-state: running;
  animation-timing-function: ease-out;
  animation-delay: 0.1s;
  display: block;
  border-radius: 100%;
  height: 5px;
  width: 5px;
  margin: 0 8px;
}

.button span b:first-child {
  animation-delay: 0s;
  margin: 0;
}

.button span b:last-child {
  animation-delay: 0.3s;
  margin: 0;
}

@keyframes stretch {
  0% {
    transform: scale(0.5);
    background-color: rgba(white, 0.6);
  }

  50% {
    background-color: rgba(white, 0.8);
  }

  100% {
    transform: scale(1);
    background-color: white;
  }
}

/** 
 * Estilos para eliminar elemntos del contrato
 */
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  display: none !important;
}

/**
* Ventana de carga... Efecto loading
*/
.loader {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border-left: 4px solid #33caff;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader_solo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.tooltip {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
}

[tooltip-hover]:hover + .tooltip {
  opacity: 1;
  visibility: visible;
}

/* #sidebar {
  visibility: hidden;
}

#sidebar #sidebar-layer{
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
}

#sidebar #sidebar-panel {
  left: -400px;
  transition: all 0.5s;
}

input[type='checkbox']:checked + #sidebar{
  visibility: visible;
}

input[type='checkbox']:checked + #sidebar #sidebar-layer{
  visibility: visible;
  opacity: 1;
}

input[type='checkbox']:checked + #sidebar #sidebar-panel {
  left: 0px;
} */